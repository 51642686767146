import React from 'react'
import videoBg from '../video/beachresort1.mp4';

const videocomponent = () => {
  return (
    <div>
      <div className="overlay"></div>
      <video src={videoBg} autoPlay loop muted />
    </div>
  )
}

export default videocomponent
